import { InboxOutlined, SyncOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Card,
  Form,
  Select,
  Space,
  Typography,
  UploadFile,
} from 'antd';
import { ColumnType, TableProps } from 'antd/es/table';
import Dragger from 'antd/es/upload/Dragger';
import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Key, useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import {
  ActivityLogStatus,
  CompaniesFilter,
  Company,
  CompanySortOn,
  InputMaybe,
  SortOrder,
  SyncLogActivity,
} from '../../../__generated__/graphql';
import { counselviseClient } from '../../../apollo';
import {
  FETCH_USER_QUERY,
  SUBSCRIPTION_PLANS_QUERY,
} from '../../../app/components/Queries';
import {
  ActiveBox,
  DownloadSimple,
  Minus,
  Pencil,
  Plus,
  RefreshIcon,
  SelectDropdownIcon,
  Trash,
} from '../../../assets/svg';
import axiosInstance from '../../../common/axiosInstance';
import {
  ACTIVE_STATUS,
  color,
  COMMON_QUERY_PARAMS,
  defaultDateTimeFormat,
  EMPTY_STATE,
  FEATURE_NOTICE_BOARD_INDIRECT_TAX,
  IMPORT_FAILED_MESSAGE,
  INDIRECT_TAX,
  LIMIT,
  PLAN_LIMIT,
  ROUTES,
  STATUS,
} from '../../../common/constants';
import CommonPagination from '../../../components/CommonPagination';
import CommonSearch from '../../../components/CommonSearch';
import TableComponent from '../../../components/CommonTable';
import StatusTag from '../../../components/CommonTag';
import PlansCards from '../../../components/dashboard/PlansCards';
import CommonModal from '../../../components/modals/CommonModal';
import MyBreadcrumb from '../../../components/MyBreadcrumb';
import VerificationStatus from '../../../components/VerificationStatus';
import useRouter from '../../../hooks/useRouter';
import { SubscriptionPlan } from '../../../types/common.type';
import CompanyModal from './component/CompanyModal';
import { SelectedRowKeysState } from './graphql/clients.type';
import { DELETE_COMPANY, SYNC_COMPANY } from './graphql/Mutation';
import { COMPANIES } from './graphql/Queries';

dayjs.extend(relativeTime);
const { Text, Title } = Typography;

const initialFilters = {
  search: '',
  skip: 0,
  limit: LIMIT,
};

const initialValue = {
  filters: { isActive: null, status: null },
};

const Clients = () => {
  const [form] = Form?.useForm();
  const [filterForm] = Form?.useForm();
  const { navigate } = useRouter();
  const [isResponse, setIsResponse] = useState<boolean>(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState<boolean>(false);
  const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<SelectedRowKeysState>(
    {},
  );
  const [responseMessage, setResponseMessage] = useState('');
  const [isBulkClientImport, setIsBulkClientImport] = useState<boolean>(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState<boolean>(false);
  const [isImport, setIsImport] = useState<boolean>(false);
  const [bulkClientImport, setBulkClientImport] = useState('');
  const [isError, setIsError] = useState<boolean>(false);
  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [syncIsFailed, setSyncIsFailed] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [editFormData, setEditFormData] = useState<Company>({});
  const [filterList, setFilterList] = useState(initialFilters);
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder?.Asc);
  const [sortOnField, setSortOnField] = useState<CompanySortOn>(
    CompanySortOn?.CreatedAt,
  );
  const [isGST, setIsGST] = useState<boolean>(false);
  const filters = Form.useWatch('filters', filterForm) ?? initialValue.filters;
  const totalSelectedRows = Object?.values(selectedRowKeys)?.flat();
  const [searchParams] = useSearchParams();
  const failedLogin =
    searchParams.get(COMMON_QUERY_PARAMS.STATUS) === 'failedLogin';

  const filter: InputMaybe<CompaniesFilter> = useMemo(
    () => ({
      isActive: filters?.isActive && filters?.isActive === 'active',
      limit: filterList?.limit,
      search: filterList?.search,
      skip: (currentPage - 1) * LIMIT,
      status: failedLogin
        ? ActivityLogStatus?.Failed
        : (searchParams.get(COMMON_QUERY_PARAMS.STATUS) as ActivityLogStatus),
      ...(failedLogin && { activity: SyncLogActivity?.Login }),
    }),
    [filters, currentPage, filterList, location.href],
  );

  useEffect(() => {
    if (location.pathname.includes(INDIRECT_TAX)) {
      setIsGST(true);
    }
  }, []);

  useEffect(() => {
    if (failedLogin) {
      filterForm.setFieldsValue({
        filters: {
          isActive: null,
          status: failedLogin
            ? ActivityLogStatus?.Failed
            : (searchParams.get(
                COMMON_QUERY_PARAMS.STATUS,
              ) as ActivityLogStatus),
          activity: SyncLogActivity?.Login,
        },
      });
    } else {
      filterForm.setFieldsValue({
        filters: {
          status: searchParams.get(COMMON_QUERY_PARAMS.STATUS),
        },
      });
    }
  }, [location.href]);

  useEffect(() => {
    setSelectedRowKeys({});
  }, [filters]);

  const [getCompanies, { data: companies, loading, refetch }] = useLazyQuery(
    COMPANIES,
    {
      fetchPolicy: 'network-only',
      onError() {},
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => {
    getCompanies({
      variables: {
        filter,
        sort: { sortBy: sortOrder, sortOn: sortOnField },
      },
    });
  }, [filter, sortOrder, sortOnField]);

  const showResponseModal = () => {
    setIsResponse(true);
    setIsFormModalOpen(false);
    setTimeout(() => {
      setResponseMessage('');
    }, 3000);
  };

  const { data: { currentUser } = {}, refetch: currentUserRefetch } = useQuery(
    FETCH_USER_QUERY,
    {
      client: counselviseClient,
      fetchPolicy: 'network-only',
    },
  );

  const [deleteCompany, { loading: loadDelete }] = useMutation(DELETE_COMPANY, {
    onError(error) {
      if (error) {
        setResponseMessage(error?.message as string);
      }
      showResponseModal();
    },
    onCompleted: (res) => {
      if (res) {
        setResponseMessage(res?.deleteCompany?.message as string);
        currentUserRefetch();
      }
      setSelectedRowKeys([]);
      setRemoveModalOpen(false);
      refetch();
      showResponseModal();
      setCurrentPage(1);
    },
  });

  const [syncCompany, { loading: loadSync }] = useMutation(SYNC_COMPANY, {
    onError() {
      setSyncIsFailed(true);
    },
    onCompleted: (res) => {
      if (res) {
        refetch();
        setSelectedRowKeys([]);
        setSyncIsFailed(false);
      }
    },
  });
  const handlePagination = (current: number) => {
    setCurrentPage(current);
  };

  const { data: { subscriptionPlans } = {}, loading: plansLoading } = useQuery(
    SUBSCRIPTION_PLANS_QUERY,
    {
      client: counselviseClient,
      variables: { filter: { limit: PLAN_LIMIT } },
      onError() {},
      fetchPolicy: 'network-only',
    },
  );

  const filteredPlans = subscriptionPlans?.data?.filter((plan) =>
    plan?.features?.includes(FEATURE_NOTICE_BOARD_INDIRECT_TAX),
  ) as SubscriptionPlan[];

  const latestSubscription = currentUser?.activeSubscriptions
    ?.filter((subscription) =>
      subscription?.features?.includes(FEATURE_NOTICE_BOARD_INDIRECT_TAX),
    )
    ?.reduce((latest, current) => {
      const latestDate = dayjs(latest?.createdAt || 0);
      const currentDate = dayjs(current?.createdAt || 0);
      return currentDate.isAfter(latestDate) ? current : latest;
    }, null);

  const activePlan =
    currentUser?.activeSubscriptions?.filter((item) =>
      item?.features?.includes(FEATURE_NOTICE_BOARD_INDIRECT_TAX),
    ) && latestSubscription?.subscriptionPlan
      ? latestSubscription
      : null;

  useEffect(() => {
    if (isResponse) {
      setTimeout(() => {
        setIsResponse(false);
      }, 3000);
    }
  }, [isResponse]);

  const cancelModal = () => {
    setIsFormModalOpen(false);
    setIsBulkClientImport(false);
    setIsImport(false);
    setBulkClientImport('');
    setEditFormData({});
    setFileList([]);
    setResponseMessage('');
    setIsUpgradeModalOpen(false);
  };

  const handleAddCompany = () => {
    const currAddedCompanyCount = currentUser?.userClient?.gstUsedCount ?? 0;
    const availableAddCompanyLimit =
      currentUser?.userClient?.gstCount === 0
        ? 2
        : currentUser?.userClient?.gstCount;

    if (currAddedCompanyCount >= (availableAddCompanyLimit ?? 2)) {
      setIsUpgradeModalOpen(true);
    } else {
      setIsFormModalOpen(true);
    }
  };

  const handleEdit = (record: Company) => {
    if (record) {
      setEditFormData(record);
    }
    setIsFormModalOpen(true);
  };

  const handleDelete = async () => {
    if (totalSelectedRows?.length === 0) {
      return;
    }
    setRemoveModalOpen(true);
  };

  const removeModalOK = async () => {
    await deleteCompany({
      variables: { where: { ids: totalSelectedRows as string[] } },
    });
  };
  const handleFileChange = ({
    fileList: newFileList,
  }: {
    fileList: UploadFile[];
  }) => {
    setFileList(newFileList);
  };

  const handleImport = async () => {
    const arrayBuffer = await fileList[0]?.originFileObj!.arrayBuffer();
    const uint8Array = new Uint8Array(arrayBuffer);
    setUploading(true);
    try {
      const response = await axiosInstance.post(
        '/v1/company/create-companies',
        uint8Array,
        {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
        },
      );
      if (response) {
        setBulkClientImport(response?.data?.message);
        setIsImport(true);
        setIsError(false);
        currentUserRefetch();
        refetch();
      }
      setIsBulkClientImport(false);
      setFileList([]);
      setUploading(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setBulkClientImport(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : 'Error importing Companies',
        );
        refetch();
        setIsError(true);
        setIsImport(true);
        setIsBulkClientImport(false);
        setFileList([]);
        setUploading(false);
      }
    }
  };

  const handleClear = () => {
    filterForm?.resetFields();
    setCurrentPage(1);
    if (searchParams.get(COMMON_QUERY_PARAMS.STATUS)) {
      navigate(`${location.pathname}`, {
        replace: true,
      });
    }
  };

  const handleRoute = ({ _id }: Company) => {
    if (_id) {
      navigate(ROUTES?.COMPANY_DASHBOARD.replace(':id', _id));
    }
  };

  const columns: ColumnType<Company>[] = [
    {
      title: 'GSTIN',
      dataIndex: 'gstNumber',
      key: 'gstNumber',
      fixed: 'left',
      render: (text, record) => (
        <a onClick={() => handleRoute(record)}>{text ?? '-'}</a>
      ),
    },
    {
      title: 'Legal Name of Business',
      dataIndex: 'businessName',
      key: 'businessName',
      sorter: true,
      render: (text) => text ?? '-',
    },
    {
      title: 'Trade Name',
      dataIndex: 'tradeName',
      key: 'tradeName',
      sorter: true,
      render: (text) => text ?? '-',
    },
    {
      title: 'User Name',
      dataIndex: 'username',
      key: 'username',
      render: (text) => text ?? '-',
    },
    {
      title: 'Taxpayer Type',
      dataIndex: 'taxPayerType',
      key: 'taxPayerType',
      render: (text) => text ?? '-',
    },
    {
      title: 'Last Downloaded',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: true,
      render: (date: string) =>
        date ? dayjs(date).format(defaultDateTimeFormat) : '-',
    },
    {
      title: 'Status',
      key: 'status',
      render: (record) => {
        const latestLog = record?.activityLogs;
        const status = latestLog?.status;
        return status ? <StatusTag status={status} /> : '-';
      },
    },
    {
      title: 'Status Message',
      key: 'message',
      render: (record) => {
        const latestLog = record?.activityLogs;
        const message = latestLog?.message;
        return message ? message : '-';
      },
    },
    {
      title: 'Action',
      fixed: 'right',
      render: (record) => (
        <Space>
          <span onClick={() => handleEdit(record)}>
            <Pencil key="edit" color={color} />
          </span>
        </Space>
      ),
    },
  ];

  const handleTableChange: TableProps<Company>['onChange'] = (
    pagination,
    _filters,
    sorter,
  ) => {
    let order: SortOrder | null = null;
    let sortOn: CompanySortOn | null = null;

    if (sorter && !Array.isArray(sorter) && sorter.field) {
      const sortFieldMap: Record<string, CompanySortOn> = {
        [CompanySortOn.TradeName]: CompanySortOn.TradeName,
        [CompanySortOn.UpdatedAt]: CompanySortOn.UpdatedAt,
        [CompanySortOn.CreatedAt]: CompanySortOn.CreatedAt,
        [CompanySortOn.BusinessName]: CompanySortOn.BusinessName,
      };

      order = sorter.order === 'ascend' ? SortOrder.Asc : SortOrder.Desc;
      sortOn = sortFieldMap[sorter.field as string] || CompanySortOn.CreatedAt;

      setSortOrder(order);
      setSortOnField(sortOn);
    }

    const newPage = pagination?.current || currentPage;
    setCurrentPage(newPage);
  };

  const rowSelection: TableProps<Company>['rowSelection'] = {
    selectedRowKeys: selectedRowKeys?.[currentPage],
    onChange: (newSelectedRowKeys: Key[]) => {
      setSelectedRowKeys((prev) => ({
        ...prev,
        [currentPage]: newSelectedRowKeys,
      }));
    },
    ...(selectedRowKeys[currentPage]?.length && {
      columnTitle: () => (
        <span
          className="d-flex pointer"
          onClick={() => {
            setSelectedRowKeys((prev) => ({
              ...prev,
              [currentPage]: [],
            }));
          }}
        >
          <Minus />
        </span>
      ),
    }),
    getCheckboxProps: () => ({
      disabled: syncIsFailed,
    }),
  };

  const handleChangeSearch = (value: string) => {
    setCurrentPage(1);
    setFilterList({
      ...filterList,
      search: value,
    });
  };

  const handleSync = async () => {
    if (totalSelectedRows?.length === 0) return;
    await syncCompany({
      variables: {
        where: { ids: totalSelectedRows as string[] },
      },
    });
  };

  const handleFieldsNavigation = (
    fieldType: string,
    value: string | null,
    additionalParams?: Record<string, string | null>,
  ) => {
    if (value) {
      searchParams.set(fieldType, value as string);
    } else {
      if (searchParams.has(fieldType)) {
        searchParams.delete(fieldType);
      }
    }
    if (additionalParams) {
      Object.entries(additionalParams).forEach(([key, val]) => {
        if (val) {
          searchParams.set(key, val);
        } else if (searchParams.has(key)) {
          searchParams.delete(key);
        }
      });
    }
    navigate(`${location?.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  return (
    <div className="container">
      <div className="mt-16 mb-16">
        <MyBreadcrumb />
      </div>
      <div className="d-flex justify-between align-center">
        <Text className="heading">Company List</Text>
        <CommonSearch
          handleChangeSearch={handleChangeSearch}
          iconPlacement="right"
          allowClear
        />
      </div>
      <div className="button-container">
        <div className="left-buttons gap-16">
          <Button
            type="primary"
            icon={<Plus color="#F4F4F4" />}
            onClick={handleAddCompany}
          >
            Add Company
          </Button>
          <Button
            type="default"
            icon={<ActiveBox color={color} height="16" />}
            onClick={() => {
              if (
                !activePlan ||
                currentUser?.userClient?.gstUsedCount ===
                  currentUser?.userClient?.gstCount
              ) {
                setIsUpgradeModalOpen(true);
              } else {
                setIsBulkClientImport(true);
              }
            }}
          >
            Add Bulk Companies
          </Button>
          <Link to={process.env.REACT_APP_COMPANY_SAMPLE_CSV ?? ''}>
            <Button type="default" icon={<DownloadSimple color={color} />}>
              Download Templates
            </Button>
          </Link>
        </div>

        <div className="right-buttons gap-16">
          <Button
            type="default"
            className="justify-center"
            icon={<Trash color={color} />}
            onClick={handleDelete}
            loading={loadDelete}
            disabled={totalSelectedRows?.length === 0}
          />

          {!syncIsFailed ? (
            <Button
              type="primary"
              onClick={handleSync}
              disabled={totalSelectedRows?.length === 0}
              loading={loadSync}
            >
              Sync
            </Button>
          ) : (
            <Button
              type="default"
              icon={<SyncOutlined />}
              danger
              onClick={handleSync}
              loading={loadSync}
            >
              Retry Sync
            </Button>
          )}
          <Button
            type="default"
            className="justify-center"
            icon={
              <span className={`${loading ? 'rotate' : ''}`}>
                <RefreshIcon color={color} />
              </span>
            }
            onClick={() => refetch()}
          />
          <Button
            type="link"
            className="underline-btn"
            onClick={() => navigate(`${ROUTES?.SYNC_LOG}`)}
          >
            View Sync log
          </Button>
        </div>
      </div>

      <Card className="table-card mt-16">
        <Form
          form={filterForm}
          layout="vertical"
          onValuesChange={() => setCurrentPage(1)}
          initialValues={initialValue}
        >
          <div className="d-flex align-center gap-16">
            <div className="gap-24 d-flex align-center">
              <Form.Item
                name={['filters', 'isActive']}
                label="Active/Inactive"
                className="select"
              >
                <Select
                  options={ACTIVE_STATUS}
                  placeholder="Select"
                  allowClear
                  suffixIcon={<SelectDropdownIcon />}
                />
              </Form.Item>
              <Form.Item
                name={['filters', 'status']}
                label="Status"
                className="select"
              >
                <Select
                  placeholder="All"
                  options={STATUS}
                  allowClear
                  suffixIcon={<SelectDropdownIcon />}
                  onChange={(value) =>
                    handleFieldsNavigation(COMMON_QUERY_PARAMS.STATUS, value)
                  }
                />
              </Form.Item>
            </div>
            <Form.Item className="d-flex align-end align-self-end">
              <Button
                type="link"
                onClick={handleClear}
                disabled={!(filters.isActive || filters.status)}
              >
                Clear All
              </Button>
            </Form.Item>
          </div>
        </Form>
        <TableComponent<Company>
          rowKey={(record) =>
            record?._id || record?.username || `temp-${Math.random()}`
          }
          columns={columns}
          loading={loading}
          dataSource={companies?.companies?.data as Company[]}
          pagination={false}
          rowSelection={rowSelection}
          scroll={{ x: 'max-content' }}
          locale={EMPTY_STATE}
          onChange={handleTableChange}
        />
        {companies?.companies?.count ? (
          <CommonPagination
            count={companies?.companies?.count}
            handlePagination={handlePagination}
            currentPage={currentPage}
          />
        ) : null}
      </Card>

      {removeModalOpen && (
        <div className="remove-Company-modal">
          <CommonModal
            open={removeModalOpen}
            className="text-center remove-modal"
            okText="Delete"
            cancelText="Cancel"
            closable={false}
            onOk={removeModalOK}
            onCancel={() => {
              setRemoveModalOpen(false);
            }}
          >
            <div className="text-center mb-24 gap-8">
              <Title level={4} className="m-0">
                Delete Company
              </Title>
              <Text className="modal-text">
                Are you sure want to permanently remove this Company?
              </Text>
            </div>
          </CommonModal>
        </div>
      )}

      {isBulkClientImport && (
        <div className="bulk-import-modal">
          <CommonModal
            title="Add Bulk Companies"
            open={isBulkClientImport}
            footer={false}
            closable={true}
            onCancel={cancelModal}
          >
            <div>
              <Dragger
                fileList={fileList}
                beforeUpload={() => false}
                onChange={handleFileChange}
                maxCount={1}
                accept=".csv"
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag CSV file to this area to upload
                </p>
              </Dragger>
              <Button
                type="primary"
                className="full-width mt-16"
                onClick={handleImport}
                disabled={fileList?.length === 0}
                loading={uploading}
              >
                Upload CSV
              </Button>
            </div>
          </CommonModal>
        </div>
      )}

      {isImport && (
        <div className="bulk-import-modal">
          <CommonModal
            open={isImport}
            footer={false}
            closable
            onCancel={cancelModal}
          >
            <div className="text-center">
              {isError && bulkClientImport?.includes(IMPORT_FAILED_MESSAGE) ? (
                <>
                  <VerificationStatus title={bulkClientImport} isError={true} />
                  <Button
                    type="link"
                    onClick={() => {
                      setIsUpgradeModalOpen(true);
                      setIsImport(false);
                    }}
                  >
                    Upgrade Plan
                  </Button>
                </>
              ) : (
                <VerificationStatus
                  title={bulkClientImport}
                  isError={isError}
                />
              )}
            </div>
          </CommonModal>
        </div>
      )}

      {isFormModalOpen && (
        <CompanyModal
          open={isFormModalOpen}
          footer={false}
          closable={true}
          onCancel={cancelModal}
          form={form}
          editData={editFormData}
          onSuccess={(response) => {
            if (response) {
              setResponseMessage(response);
              currentUserRefetch();
            }
            setIsError(false);
            setIsResponse(!editFormData?._id);
            refetch();
            filterForm?.resetFields();
            showResponseModal();
          }}
          onError={(response) => {
            if (response) {
              showResponseModal();
              setResponseMessage(response);
            }
            setIsError(true);
          }}
        />
      )}

      {isResponse && (
        <div className="add-user-successfully">
          <CommonModal
            open={isResponse}
            footer={false}
            closable={false}
            onCancel={() => setIsResponse(false)}
          >
            <div className="text-center">
              <VerificationStatus title={responseMessage} isError={isError} />
            </div>
          </CommonModal>
        </div>
      )}

      <CommonModal
        open={isUpgradeModalOpen}
        footer={false}
        closable={true}
        onCancel={() => setIsUpgradeModalOpen(false)}
        maskClosable={false}
        centered={true}
        className="plans-container"
        width={1086}
      >
        <PlansCards
          activePlan={activePlan}
          plans={filteredPlans}
          loading={plansLoading}
          userClient={currentUser?.userClient}
          isGst={isGST}
        />
      </CommonModal>
    </div>
  );
};

export default Clients;
